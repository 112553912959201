import React from 'react';

import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';

const Current = () => (
  <Layout>
    <Sidebar showTabs="false" />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="hearts"
      >
        <div className="w-100">
          <h2 className="mb-5" style={{ textAlign: 'center' }}>
            Happiness in our families السعادة في بيوتنا{' '}
          </h2>
          <a
            href="https://www.youtube.com/playlist?list=PLNT8BTFvQYHKJMa-hKZvNDe0xgknoHwdY"
            target="blank"
          ></a>
          <p style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: 25,
                color: 'red',
                marginTop: 50,
                fontWeight: 'bold',
                letterSpacing: 0,
                fontFamily: 'Saira Extra Condensed',
                textAlign: 'center',
              }}
            >
              " وَالَّذِينَ يَقُولُونَ رَبَّنَا هَبْ لَنَا مِنْ أَزْوَاجِنَا
              وَذُرِّيَّاتِنَا قُرَّةَ أَعْيُنٍ وَاجْعَلْنَا لِلْمُتَّقِينَ
              إِمَامًا "
            </div>
            and who pray “O our Sustainer! Grant that our spouses and our
            offspring be a joy to our eyes, and cause us to be foremost among
            those who are conscious of Thee!” Al-Furqan (The Criterion) 25:74
            <div style={{ textAlign: 'center' }}>
              " وَكَيْفَ تَأْخُذُونَهُ{' '}
              <span
                style={{
                  fontSize: 25,
                  color: 'red',
                  marginTop: 50,
                  fontWeight: 'bold',
                  letterSpacing: 0,
                  fontFamily: 'Saira Extra Condensed',
                  textAlign: 'center',
                }}
              >
                وَقَدْ أَفْضَى بَعْضُكُمْ إِلَى بَعْضٍ وَأَخَذْنَ مِنكُم
                مِّيثَاقًا غَلِيظًا "
              </span>
            </div>
            <div>
              And how could you take it away after you have given yourselves to
              one another, and she has received a most solemn pledge from you?
              An-Nisa (The Women) 4:21
            </div>
            <span
              style={{
                fontSize: 25,
                color: 'blue',
                marginTop: 50,
                fontWeight: 'bold',
                letterSpacing: 0,
                fontFamily: 'Saira Extra Condensed',
                textAlign: 'center',
              }}
            >
              قال رسول الله صلى الله عليه وسلم {' - '}
            </span>
            <span
              style={{
                fontSize: 25,
                color: 'red',
                marginTop: 50,
                fontWeight: 'bold',
                letterSpacing: 0,
                fontFamily: 'Saira Extra Condensed',
                textAlign: 'center',
              }}
            >
              <a
                target="blank"
                href="https://www.islamweb.net/ar/article/182811/%D8%A7%D8%B3%D8%AA%D9%88%D8%B5%D9%88%D8%A7-%D8%A8%D8%A7%D9%84%D9%86%D8%B3%D8%A7%D8%A1-%D8%AE%D9%8A%D8%B1%D8%A7"
              >
                استوصوا بالنساء خيرا، فإن المرأة خلقت من ضلع، وإن أعوج شيء في
                الضلع أعلاه، فإن ذهبت تقيمه كسرته، وإن تركته لم يزل أعوج،
                فاستوصوا بالنساء -
              </a>{' '}
              <span>متفق عليه</span>
            </span>
            <div>
              The Prophet (PBUH) said: I advise you to take care of the women,
              for they are created from a rib and the most crooked portion of
              the rib is its upper part; if you try to straighten it, it will
              break, and if you leave it, it will remain crooked, so I urge you
              to take care of the women. [Agreed Upon Bukhari and Muslim]
            </div>
            <div
              style={{
                fontSize: 25,
                color: 'blue',
                marginTop: 50,
                fontWeight: 'bold',
                letterSpacing: 0,
                fontFamily: 'Saira Extra Condensed',
                textAlign: 'center',
              }}
            ></div>
            <p>
              <span>قال رسول الله صلى الله عليه وسلم</span>
              {' : '}
              <span
                style={{
                  fontSize: 25,
                  color: 'red',
                  marginTop: 50,
                  fontWeight: 'bold',
                  letterSpacing: 0,
                  fontFamily: 'Saira Extra Condensed',
                  textAlign: 'center',
                }}
              >
                {' '}
                لَا يَفْرَكْ مُؤْمِنٌ مُؤْمِنَةً إِنْ كَرِهَ مِنْهَا خُلُقًا
                رَضِيَ مِنْهَا آخَرَ
              </span>
              <p>
                Messenger of Allah (ﷺ) said, "A believer must not hate (his
                wife) believing woman; if he dislikes one of her characteristics
                he will be pleased with another". [Muslim].
              </p>
            </p>
          </p>

          <h4>References:</h4>
          <ul>
            <li>
              <a
                href="https://www.youtube.com/playlist?list=PL334BFE277C8B938B"
                target="blank"
              >
                الجنة في بيوتنا عمرو خالد (YouTube Playlist):
              </a>
            </li>
            <ol>
              <li>
                <a
                  href="https://iloveallah.net/static/happy_family_1.mp3"
                  target="blank"
                >
                  [Arabic Only - MP3] المقدمة -الجنة في بيوتنا عمرو خالد
                </a>
              </li>
              <li>
                <a
                  href="https://iloveallah.net/static/happy_family_2.mp3"
                  target="blank"
                >
                  [Arabic Only - MP3] قيمة الأسرة - الجنة في بيوتنا عمرو خالد
                </a>
              </li>
              <li>
                <a
                  href="https://iloveallah.net/static/happy_family_3.mp3"
                  target="blank"
                >
                  [Arabic Only - MP3] الرحمة - الجنة في بيوتنا عمرو خالد
                </a>
              </li>
              <li>
                <a
                  href="https://iloveallah.net/static/happy_family_4.mp3"
                  target="blank"
                >
                  [Arabic Only - MP3] حلم الأسرة - الجنة في بيوتنا عمرو خالد
                </a>
              </li>
              <li>
                <a
                  href="https://iloveallah.net/static/happy_family_5.mp3"
                  target="blank"
                >
                  [Arabic Only - MP3] الجزر المنعزلة - الجنة في بيوتنا عمرو خالد
                </a>
              </li>
            </ol>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default Current;
